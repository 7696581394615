.summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 20px;

  .question-answer {
    width: 100%;
    .answer {
      flex-direction: row;
      padding: 5px 15px;
      span {
        flex: 1 0 auto;
      }
      img {
        width: 60px;
        height: auto;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}
