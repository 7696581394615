@import "../global.scss";

.page {
  background-color: $primaryGreen;
  color: $primaryFontColor;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .help-btn {
    position: absolute;
    z-index: 5;

    top: 18px;
    right: 20px;

    background: none;
    border: none;
    color: $primaryFontColor;
    padding: 5px 10px;
  }

  .content {
    position: relative;
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 4px 10px #00000011;
  }

  header {
    flex: 0 0 100px;
    background: $secondaryGreen;
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: stretch;

    .content {
      padding: 20px;
      box-shadow: 0 -4px 10px #00000011;
    }

    .back-btn {
      position: relative;
      display: block;
      background: none;
      border: none;
      color: $primaryFontColor;
      font-size: 16px;

      &:focus {
        @include focused();
      }
    }
  }

  section {
    flex-grow: 1;
    display: flex;
    align-items: stretch;

    .content {
      align-items: center;
      justify-content: center;
    }

    img {
      max-width: 80%;
    }
  }

  footer {
    flex: 0 0 auto;
    position: sticky;
    bottom: 0;
    background: $primaryGreen;

    .content {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
  }

  &.white {
    background-color: $primaryFontColor;
    color: $primaryGreen;

    header {
      background: $primaryFontColor;
    }

    button.submit-btn:enabled {
      background: $primaryGreen;
      color: $primaryFontColor;
    }

    button.back-btn {
      color: $primaryGreen;
    }
  }

  &.map-page {
    section {
      position: relative;
      background-color: white;

      .react-openlayers--map {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .zoom-control--root {
        display: none;
      }
    }

    footer {
      position: absolute;
      bottom: 0;
      left: 50%;
      max-width: 720px;
      width: 100%;
      transform: translate(-50%, 0);
      background: none;

      &,
      .content {
        pointer-events: none;
        box-shadow: none;
      }

      .content > * {
        pointer-events: all;
      }
    }
  }
}
