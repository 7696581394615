$primaryGreen: #00806a;
$secondaryGreen: #128974;
$primaryFontColor: #fffefa;
$secondaryFontColor: #00806a;
$deactivatedColor: #c9c9c9;
$btnBackground: #fffefa;

$outlineColor: #fffefa;

@mixin focused() {
  outline: none;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    border: 2px solid $outlineColor;
    border-radius: 19px;
  }
}
