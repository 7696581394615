@import "./global.scss";

.app {
  .intro {
    padding: 10px 20px;
    text-align: center;
    max-width: 600px;

    img {
      max-height: 40vh;

      @media (max-height: 420px) {
        float: left;
        margin-right: 20px;
      }
    }
  }

  h2 {
    font-size: 1.4em;
    margin: 0.8em 0 0.2em;
  }
  h3 {
    font-size: 1em;
    margin: 0.2em 0 0.5em;
  }

  form {
    width: 100%;
    padding: 10px 20px;
  }

  label {
    display: block;
    margin-top: 1em;
    font-weight: 600;
  }

  textarea {
    height: 25vh;
    max-height: 250px;
    min-height: 60px;

    background-color: #fff1;
    color: $primaryFontColor;
    outline: none;

    border: 2px solid transparentize($btnBackground, 0.5);
    opacity: 0.8;

    &:focus {
      border-color: $btnBackground;
      opacity: 1;
    }

    transition: border-color 200ms ease-out, opacity 200ms ease-out;
  }

  button {
    cursor: pointer;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;

    background: rgba(0, 0, 0, 0.6);
  }
}
