/*@import "../style/variables.scss";*/

.point-geometry-picker {
  .btn-remove-point {
    position: absolute;
    left: 10px;
    bottom: 55px;
    background: rgba(160, 0, 0, 0.8);

    &:hover {
      background: #b00;
    }
  }
}
