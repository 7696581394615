@import "./global.scss";

body {
  * {
    box-sizing: border-box;
  }
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  background: $primaryGreen;
}

button:disabled,
button[disabled] {
  background: $deactivatedColor;
  color: #666;
}
