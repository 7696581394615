//@import "../style/variables";

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: darken(red /*$themeColor*/, 10%);

  .loading-dots {
    margin-right: 40px;
  }
}

.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff6;
  animation: fadein 500ms ease-out;
}

.loading-dots {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  text-align: center;

  span {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin: 20px -5px;

    background: red; //$themeColor;
    animation: loading-dots-anim 1.5s ease-out infinite;
    transform: rotate(0deg) translate(0, -20px);
    opacity: 0;
  }

  span:nth-child(1) {
    animation-delay: 200ms;
  }
  span:nth-child(2) {
    animation-delay: 400ms;
  }
  span:nth-child(3) {
    animation-delay: 600ms;
  }
  span:nth-child(4) {
    animation-delay: 800ms;
  }
}

@keyframes loading-dots-anim {
  from {
    transform: rotate(0deg) translate(0, -20px);
  }
  50% {
    opacity: 1;
  }
  to {
    transform: rotate(360deg) translate(0, -20px);
    opacity: 0;
  }
}
