@import "../global.scss";

.zoom-to-position {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 10px;

  background-color: $primaryGreen;
  color: $primaryFontColor;
  border: none;
  line-height: 2;

  svg {
    width: 24px;
    vertical-align: middle;
    margin-right: 10px;
  }

  &:disabled {
    background-color: $primaryGreen;
  }
}
