.textarea-field {
  width: 100%;
  margin: auto;

  textarea {
    width: 100%;
    max-height: 350px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    padding: 10px;
    font-size: 18px;
  }
}
