.icon {
  svg {
    height: 1.2em;
    vertical-align: text-top;
  }

  &.r90 {
    svg {
      transform: rotate(90deg);
    }
  }

  &.r180 {
    svg {
      transform: rotate(180deg);
    }
  }

  &.r270 {
    svg {
      transform: rotate(270deg);
    }
  }
}

.icon-list {
  margin: 20px auto;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  background: #eee;

  & > div {
    padding: 10px;
    width: 400px;

    .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      font-size: 20px;
      margin-right: 10px;
      border-radius: 2px;

      background: #fff;
      vertical-align: middle;

      svg {
        display: block;
      }
    }
  }
}
