@import "../global.scss";

.help-modal {
  display: block;
  width: 600px;
  max-width: 80%;
  height: 400px;
  max-height: 80%;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 50px;
  }

  .close-btn {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 20px;
    border: 0;
    background: $primaryGreen;
    color: white;
    padding: 5px 10px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;

  background: rgba(0, 0, 0, 0.6);
}
