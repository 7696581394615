@import "../global.scss";

.options-field {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 350px;
  margin: 0 auto;

  .option {
    width: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 8px 16px;
    margin: 10px 10px 0 0;
    background: $btnBackground;
    color: $secondaryFontColor;
    cursor: pointer;
    border-radius: 15px;
    text-align: center;
    transition: background-color 100ms ease-out, color 100ms ease-out;

    img {
      display: block;
      height: 80px;
      margin: 0 auto;
    }

    input {
      display: block;
      width: 0px;
      height: 0px;
      appearance: none;
      -webkit-appearance: none;

      &:focus {
        @include focused();
      }
    }

    &:hover {
      background: #f7f7f7;
    }

    border: 4px solid $btnBackground;

    &.selected {
      background: $secondaryGreen;
      color: $primaryFontColor;
    }
  }

  &.rating {
    max-width: none;
    .option {
      width: 100px;
      height: 100px;
      img {
        height: 50px;
      }
    }
  }

  &.affiliation {
    max-width: none;
  }

  &.alder,
  &.affiliation,
  &.location-type {
    @media (max-height: 420px) {
      max-width: none;
      .option {
        width: 110px;
        padding: 10px;
        img {
          width: 60px;
          height: auto;
        }
      }
    }
    @media (max-width: 420px) {
      .option {
        width: 100%;
        flex-direction: row;
        padding: 5px 15px;
        img {
          display: inline-block;
          width: 50px;
          height: auto;
        }
        span {
          flex: 1 0 auto;
        }
      }
    }
  }
}
