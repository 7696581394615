@import "../global.scss";

.submit-btn {
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  font-weight: 600;
  padding: 15px 10px;
  border: 0;
  border-radius: 15px;
  background: $btnBackground;
  color: #00a388;
  font-size: 20px;
  outline: none;

  &:focus {
    @include focused();
  }
}

.map-page .submit-btn {
  background: $primaryGreen;
  color: $primaryFontColor;

  &:disabled {
    background-color: desaturate(lighten($primaryGreen, 20%), 60%);
  }
}
